import React, { useState, useEffect } from 'react';
import '../App.css';

const ConfigTable = ({ onUpdateConfig }) => {
    const [rows, setRows] = useState([
        { key: '', value: '' },
    ]);

    useEffect(() => {
        const savedRows = localStorage.getItem('configRows');
        if (savedRows) {
            try {
                const parsedRows = JSON.parse(savedRows);
                setRows(parsedRows);
            } catch (error) {
                console.error("Error parsing saved rows: ", error);
            }
        }
    }, []);

    const handleSave = () => {
        localStorage.setItem('configRows', JSON.stringify(rows));
        onUpdateConfig(rows);
    };

    const handleAddRow = () => {
        setRows([...rows, { key: '', value: '' }]);
    };

    const handleDeleteRow = (index) => {
        const newRows = [...rows];
        newRows.splice(index, 1);
        setRows(newRows);
    };

    const handleInputChange = (index, type, value) => {
        const newRows = [...rows];
        newRows[index][type] = value;
        setRows(newRows);
    };

    return (
        <div className="config-section">
            <h2>Config Table</h2>
            <button onClick={handleAddRow}>Add Row</button>
            <button onClick={handleSave}>Save</button>
            <table className="config-table">
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, index) => (
                        <tr key={index}>
                            <td>
                                <input
                                    type="text"
                                    value={row.key}
                                    onChange={(e) => handleInputChange(index, 'key', e.target.value)}
                                />
                            </td>
                            <td>
                                <textarea
                                    value={row.value}
                                    onChange={(e) => handleInputChange(index, 'value', e.target.value)}
                                />
                            </td>
                            <td>
                                <button onClick={() => handleDeleteRow(index)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ConfigTable;