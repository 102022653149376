import React from 'react';

function Message({ type, content }) {
  return (
    <div className={`message ${type}`}>
      {content}
    </div>
  );
}

export default Message;
