import '../App.css';
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Message from './Message';
import ConfigTable from './ConfigTable';
import ReactMarkdown from 'react-markdown';

// const BACKEND_API_URL = 'http://127.0.0.1:5000/ask';
// const PREDICTION_API_URL = 'http://127.0.0.1:5000/predict';
// const TRANSFORM_API_URL = 'http://127.0.0.1:5000/transform';

const BACKEND_API_URL = 'https://embedding-api-dev.wl.r.appspot.com/ask';
const PREDICTION_API_URL = 'https://embedding-api-dev.wl.r.appspot.com/predict';
const TRANSFORM_API_URL = 'https://embedding-api-dev.wl.r.appspot.com/transform';
const MAX_CHAR = 100000000;

const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
};

const backupNotes = (notes) => {
    localStorage.setItem('notesBackup', notes);
};

const debouncedBackupNotes = debounce(backupNotes, 2000);

function ChatWindow() {
    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState('');
    const messagesEndRef = useRef(null);
    const notesTextAreaRef = useRef(null);
    const chatInputRef = useRef(null);
    const [notes, setNotes] = useState('');
    const [isChatVisible, setIsChatVisible] = useState(true);
    // const [isChatFocused, setIsChatFocused] = useState(true);  // New state for focus toggle
    // const [hasPseudoSelection, setHasPseudoSelection] = useState(false);
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0 });
    const [selectedText, setSelectedText] = useState('');
    const [intermediateTransform, setIntermediateTransform] = useState('');
    const [isEditingNotes, setIsEditingNotes] = useState(false);
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [configData, setConfigData] = useState('');  // New state variable for config data

    const handleUpdateConfig = (newConfig) => {  // New function to handle config updates
        setConfigData(newConfig);
    };

    useEffect(() => {
        const savedNotes = localStorage.getItem('notesBackup');
        if (savedNotes) {
            setNotes(savedNotes);
        }
    }, []);

    useEffect(() => {
        debouncedBackupNotes(notes);
    }, [notes]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    // const handleDocumentClick = (event) => {
    //     if (notesTextAreaRef.current && !notesTextAreaRef.current.contains(event.target)) {
    //         // Click was outside the notes area
    //         setIsEditingNotes(false);
    //     }
    // };

    // useEffect(() => {
    //     // Attach the click event listener
    //     document.addEventListener("click", handleDocumentClick);
    //     // Cleanup
    //     return () => {
    //         // Remove the click event listener
    //         document.removeEventListener("click", handleDocumentClick);
    //     };
    // }, []);  // Empty dependency array means this effect runs once when the component mounts

    useEffect(() => {
        if (isEditingNotes && notesTextAreaRef.current) {
            notesTextAreaRef.current.focus();
        }
    }, [isEditingNotes]);

    const handleSendMessage = async (content) => {
        if (!content.trim()) return;
        try {
            const response = await axios.post(BACKEND_API_URL, { content: content, notes: notes });
            const aiMessage = response.data;
            setMessages(prev => [
                ...prev,
                { type: 'user', content: content, id: Date.now() },
                { type: 'ai', content: aiMessage, id: Date.now() + 1 }
            ]);
            setUserInput('');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const downloadNotesAsMd = () => {
        const element = document.createElement("a");
        const file = new Blob([notes], { type: 'text/markdown' });
        element.href = URL.createObjectURL(file);
        element.download = "notes.md";
        document.body.appendChild(element);
        element.click();
    };

    const handlePrediction = async (currentText) => {
        if (currentText.length > MAX_CHAR) {
            currentText = currentText.slice(-1 * MAX_CHAR, -1)
        }
        try {
            const response = await axios.post(PREDICTION_API_URL, { 'notes_end': currentText, config: configData });
            // const response = await axios.post(PREDICTION_API_URL, { 'notes': currentText });
            const predictedText = response.data['predicted_text'];
            return predictedText;
        } catch (error) {
            console.error('Error fetching prediction:', error);
            return '';
        }
    };

    const handleNotesChange = async (e) => {
        const currentText = e.target.value;
        if (currentText !== notes) {  // Check if there's an actual change
            setUndoStack(prevUndoStack => [...prevUndoStack, notes]);  // Push current state onto undo stack
            setRedoStack([]);  // Clear redo stack when new change is made
            setNotes(currentText);
        }
    };

    const handleTabPress = async (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();

            const cursorPosition = e.target.selectionStart;
            const textBeforeCursor = notes.substring(0, cursorPosition);

            const predictedText = await handlePrediction(textBeforeCursor);
            const updatedNotes = textBeforeCursor + predictedText + notes.substring(cursorPosition);

            setNotes(updatedNotes);

            setTimeout(() => {
                notesTextAreaRef.current.setSelectionRange(cursorPosition, cursorPosition + predictedText.length);
            }, 0);
        }
    };

    const handleTextareaResize = () => {
        chatInputRef.current.style.height = 'auto'; // reset the height
        chatInputRef.current.style.height = `${chatInputRef.current.scrollHeight}px`;
    };

    const handleContextMenu = (e) => {
        e.preventDefault();
        const textAreaSelection = e.target.value.substring(
            e.target.selectionStart,
            e.target.selectionEnd
        );
        setSelectedText(textAreaSelection);
        if (textAreaSelection) {
            setContextMenu({
                visible: true,
                x: e.clientX,
                y: e.clientY
            });
        }
    };

    const handleTransformationRequest = async (option) => {
        // const selectedText = window.getSelection().toString();
        try {
            const response = await axios.post(TRANSFORM_API_URL, {
                option: option,
                selectedText: selectedText,
                notes: notes
            });
            setIntermediateTransform(response.data);
        } catch (error) {
            console.error('Error transforming text:', error);
        }
    };

    const applyTransformation = () => {
        // Only proceed if textarea is currently rendered
        if (isEditingNotes && notesTextAreaRef.current) {
            const selectedTextStart = notesTextAreaRef.current.selectionStart;
            const selectedTextEnd = notesTextAreaRef.current.selectionEnd;
            const beforeSelectedText = notes.substring(0, selectedTextStart);
            const afterSelectedText = notes.substring(selectedTextEnd);
            const updatedNotes = beforeSelectedText + intermediateTransform + afterSelectedText;

            setNotes(updatedNotes);
            setIntermediateTransform('');
            setContextMenu({ visible: false, x: 0, y: 0 });
        } else {
            console.warn("Textarea is not available for transformations.");
        }
    };


    const cancelTransformation = () => {
        setIntermediateTransform('');
        setContextMenu({ visible: false, x: 0, y: 0 });
    };

    const undoChanges = () => {
        if (undoStack.length === 0) return;
        setRedoStack(prevRedoStack => [...prevRedoStack, notes]);
        const prevState = undoStack.pop();
        setUndoStack([...undoStack]);  // Update state to trigger re-render
        setNotes(prevState);
    };

    const redoChanges = () => {
        if (redoStack.length === 0) return;
        setUndoStack(prevUndoStack => [...prevUndoStack, notes]);
        const nextState = redoStack.pop();
        setRedoStack([...redoStack]);  // Update state to trigger re-render
        setNotes(nextState);
    };

    return (
        <div className="main-wrapper">
            <div className="container">
                <div className="notes-section">
                    <button onClick={undoChanges}>Undo</button>
                    <button onClick={redoChanges}>Redo</button>
                    <button onClick={() => setIsEditingNotes(!isEditingNotes)}>
                        {isEditingNotes ? "Switch to View Mode" : "Switch to Edit Mode"}
                    </button>
                    <button onClick={downloadNotesAsMd}>Save Notes as .md</button>
                    {isEditingNotes ? (
                        <textarea
                            ref={notesTextAreaRef}
                            value={notes}
                            onChange={handleNotesChange}
                            onKeyDown={handleTabPress}
                            onContextMenu={handleContextMenu}
                            // className={hasPseudoSelection ? "pseudo-selected" : ""}
                            placeholder="Paste your notes here..."
                        />
                    ) : (
                        <div onClick={() => setIsEditingNotes(true)} style={{ cursor: 'text' }}>
                            <ReactMarkdown className="markdown-body">{notes}</ReactMarkdown>
                        </div>
                    )}
                    <div className="chat-toggle">
                        <input
                            type="checkbox"
                            id="chatToggle"
                            checked={isChatVisible}
                            onChange={() => setIsChatVisible(prev => !prev)}
                        />
                        <label htmlFor="chatToggle">{isChatVisible ? "Hide Chat" : "Show Chat"}</label>
                    </div>
                </div>
                <div className={`chat-section ${isChatVisible ? "" : "hidden"}`}>
                    <div className="messages-list">
                        {messages.map(msg => (
                            <Message key={msg.id} type={msg.type} content={`${msg.type === 'user' ? 'User: ' : 'AI: '}${msg.content}`} />
                        ))}
                        <div ref={messagesEndRef}></div>
                    </div>
                    <div className="message-input">
                        <textarea
                            ref={chatInputRef}
                            value={userInput}
                            onChange={(e) => setUserInput(e.target.value)}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    handleSendMessage(userInput);
                                }
                            }}
                            onInput={handleTextareaResize}
                            placeholder="Type your message..."
                        />
                        <button onClick={() => handleSendMessage(userInput)}>Send</button>
                    </div>
                </div>
                {
                    contextMenu.visible && (
                        <div
                            className="custom-context-menu"
                            style={{ top: `${contextMenu.y}px`, left: `${contextMenu.x}px` }}
                        >
                            <div onClick={() => handleTransformationRequest("Suggest alternatives to")}>Suggest alternatives to</div>
                            {/* this one is custom - <div onClick={() => handleTransformationRequest("Rewrite without using")}>Rewrite without using</div> */}
                            <div onClick={() => handleTransformationRequest("Make it formal")}>Make it formal</div>
                            <div onClick={() => handleTransformationRequest("Write a sentence about")}>Write a sentence about</div>
                            <div onClick={() => handleTransformationRequest("Convert to doc")}>Convert to doc</div>
                            <hr />
                            <input
                                type="text"
                                placeholder="Propose a transformation..."
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter' && e.target.value) {
                                        handleTransformationRequest(e.target.value);
                                    }
                                }}
                            />
                            <input
                                type="text"
                                value={intermediateTransform}
                                onChange={(e) => setIntermediateTransform(e.target.value)}
                                placeholder="Transformed text..."
                            />
                            <button onClick={applyTransformation}>OK</button>
                            <button onClick={cancelTransformation}>Cancel</button>
                        </div>
                    )
                }
            </div>
            <ConfigTable onUpdateConfig={handleUpdateConfig} />  {/* Pass the callback function to update config */}
        </div>
    );
}

export default ChatWindow;
